var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('div',{staticClass:"row justify-content-end mb-3"},[_c('a',{staticClass:"btn btn-primary float-right",attrs:{"href":"#"},on:{"click":function($event){return _vm.goBack()}}},[_c('i',{staticClass:"fa fa-arrow-left fa-fw"}),_vm._v(" назад")])]),(!_vm.isLoadingLogs)?_c('div',[(_vm.heading)?_c('h1',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.heading))]):_vm._e(),(
          _vm.$route.params.group &&
            _vm.items.length === 1 &&
            _vm.items[0].description.includes('created')
        )?_c('h2',{staticClass:"text-center"},[_vm._v(" Не са правени промени ")]):_vm._e(),(_vm.items.length > 0)?[_vm._l((_vm.items),function(item){return [_c('div',{key:item.id,staticClass:"row t-row"},[_c('h4',{staticClass:"col-5 t-head text-black"},[_vm._v(" "+_vm._s(_vm.$t(`LOGS.${item.description}`))+" ")]),_c('h4',{staticClass:"col-4 t-head text-black causerName"},[[_vm._v(_vm._s(item.causer ? item.causer.name : ""))]],2),_c('h4',{staticClass:"col-3 t-head text-black createdAt"},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]),_vm._l((item.properties),function(propps,inde){return _c('div',{key:inde,staticClass:"col-12"},_vm._l((propps),function(attr,label){return _c('span',{key:label,staticClass:"d-flex"},[(
                    attr !== '' &&
                      !label.includes('id') &&
                      !label.includes('typeable_type') &&
                      !label.includes('shift')
                  )?[_vm._v(_vm._s(_vm.$t(`LABEL_LOGS.${item.type}.${label}`))+": "),(
                      attr === true ||
                        attr === false ||
                        attr === 1 ||
                        attr === 0
                    )?[_vm._v(" "+_vm._s(_vm.$t(`LABEL_LOGS.${attr}`))+" ")]:(label == 'start_date' || label == 'signed_at')?[_vm._v(" "+_vm._s(_vm.formatDate(attr))+" ")]:[_vm._v(" "+_vm._s(attr)+" ")]]:_vm._e()],2)}),0)})],2)]})]:_vm._e()],2):_vm._e(),_c('v-progress-linear',{staticClass:"mt-5",attrs:{"color":"#25bdad","buffer-value":"0","stream":"","reverse":"","active":_vm.isLoadingLogs}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }