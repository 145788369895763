<template>
  <div>
    <v-container>
      <div class="row justify-content-end mb-3">
        <a href="#" class="btn btn-primary float-right" v-on:click="goBack()">
          <i class="fa fa-arrow-left fa-fw"></i> назад</a
        >
      </div>

      <div v-if="!isLoadingLogs">
        <h1 v-if="heading" class="text-center">{{ heading }}</h1>
        <h2
          v-if="
            $route.params.group &&
              items.length === 1 &&
              items[0].description.includes('created')
          "
          class="text-center"
        >
          Не са правени промени
        </h2>
        <template v-if="items.length > 0">
          <template v-for="item in items">
            <div class="row t-row" v-bind:key="item.id">
              <h4 class="col-5 t-head text-black">
                {{ $t(`LOGS.${item.description}`) }}
              </h4>
              <h4 class="col-4 t-head text-black causerName">
                <template>{{ item.causer ? item.causer.name : "" }}</template>
              </h4>
              <h4 class="col-3 t-head text-black createdAt">
                {{ formatDate(item.created_at) }}
              </h4>
              <div
                class="col-12"
                v-bind:key="inde"
                v-for="(propps, inde) in item.properties"
              >
                <span
                  class="d-flex"
                  v-bind:key="label"
                  v-for="(attr, label) in propps"
                >
                  <template
                    v-if="
                      attr !== '' &&
                        !label.includes('id') &&
                        !label.includes('typeable_type') &&
                        !label.includes('shift')
                    "
                    >{{ $t(`LABEL_LOGS.${item.type}.${label}`) }}:
                    <template
                      v-if="
                        attr === true ||
                          attr === false ||
                          attr === 1 ||
                          attr === 0
                      "
                    >
                      {{ $t(`LABEL_LOGS.${attr}`) }}
                    </template>
                    <template
                      v-else-if="label == 'start_date' || label == 'signed_at'"
                    >
                      {{ formatDate(attr) }}
                    </template>
                    <template v-else>
                      {{ attr }}
                    </template>
                  </template>
                </span>
              </div>
            </div>
          </template>
        </template>
      </div>

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingLogs"
      ></v-progress-linear>
    </v-container>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { GET_LOG } from "@/store/logs.module";

import moment from "moment";

import { mapGetters } from "vuex";

export default {
  name: "ListLoad",

  components: {},

  data() {
    return {
      code: "",
      action: "products",
      tableOptions: {
        showSearch: false,
        draggableCols: true,
        selectable: true,
        rowsPerPage: [2, 10, 20, 50],
        maxVisibleButtons: 5
      },
      heading: null,
      items: [],
      totalItems: 0,
      totalPages: 0,
      gg: false
    };
  },
  computed: {
    ...mapGetters(["isLoadingLogs"]),
    orderIcon: function() {
      let vm = this;

      return vm.headers.orderType == "asc" ? "▲" : "▼";
    }
  },
  watch: {},
  mounted() {
    let vm = this;

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Продукти" }]);

    vm.fetchData();
  },
  methods: {
    formatDate: function(dateAttr) {
      if (!dateAttr) {
        return;
      }

      let format = "DD.MM.YYYY";
      let date = moment(dateAttr);

      if (date.hour() > 0) {
        format = "HH:mm " + format;
      }

      return date.format(format);
    },

    fetchItem: function() {},

    fetchData: function() {
      let vm = this;

      let payload = {
        id: this.$route.params.id,
        type: this.$route.params.type,
        group: this.$route.params.group
      };

      vm.$store
        .dispatch(GET_LOG, payload)
        .then(data => {
          vm.$nextTick(function() {
            vm.heading = data.data.heading;
            vm.items = data.data.items;
            // vm.totalItems = data.data.recordsTotal;
            // vm.totalPages = data.data.totalPages;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
          }
        });
    }
  },

  beforeDestroy: function() {
    this.items = null;
    delete this.items;
  }
};
</script>
